import Vue from 'vue';
import Vuex from 'vuex';
/*
*****************************
start general section
*****************************
*/
import { notifications } from './notifications.module';
import { table } from './table.module';


import { auth } from '@/modules/auth/store/auth.module';

/*
*****************************
start form section
*****************************
*/
import { form } from '@/modules/form/store/form.module';
import { question } from '@/modules/form/store/question.module';
import { question_category } from '@/modules/form/store/question_category.module';
/*
*****************************
start user section
*****************************
*/

import { type } from '@/modules/users/store/type.module';
import { user } from '@/modules/users/store/user.module';
import { category } from '@/modules/users/store/category.module';

/*
*****************************
start assign camp section
*****************************
*/
import { square } from '@/modules/assign_camp/store/square.module';
import { camp } from '@/modules/assign_camp/store/camp.module';
import { assign } from '@/modules/assign_camp/store/assign.module';
import { appointment } from '@/modules/assign_camp/store/appointment.module';
import { assign_form } from '@/modules/assign_camp/store/assign_form.module';
import { station } from '@/modules/assign_camp/store/station.module';

/*
*****************************
start order section
*****************************
*/

import { order } from '@/modules/orders/store/order.module';
import { pledge } from '@/modules/orders/store/pledge.module';
import { service } from '@/modules/orders/store/service.module';
import { temp_licenses } from '@/modules/orders/store/temp_licenses.module';
import { eng_guides } from '@/modules/orders/store/eng_guides.module';
import { specialists } from '@/modules/orders/store/specialists.module';

import { ticket } from '@/modules/tickets/store/ticket.module';
import { ticket_subject } from '@/modules/tickets/store/ticket_subject.module';

import { License } from '@/modules/license/store/License.module';

import countries from '@/modules/assign_camp/store/country.module';

import { re_assign_order } from '@/modules/reassign/store/re_assign_order.module';

import { notification } from '@/modules/notifications/store/notifications.module';
Vue.use(Vuex);
// Vue.use(router);
export default new Vuex.Store({
    state: () => ({
        collection: '',
        name_function: '',
        id: '',
        parent_page: '',
        url: null,
        card: {},
        action_service: true,
    }),
    mutations: {
        SET_ACTION_SERVICE(state, data) {
            state.action_service = data
        },
        SET_COLLECTION(state, data) {
            state.collection = data
        },
        SET_URL(state, data) {
            state.url = data
        },
        SET_FUNCTION(state, data) {
            state.name_function = data;
        },
        SET_ID(state, data) {
            state.id = data;
        },
        SET_CARD(state, data) {
            state.card = data;
        },
        SET_CARD_LOADING(state, data) {
            state.card.loading = data;
        },
        SET_PARENT_PAGE(state, data) {
            state.parent_page = data;
        },
        // SET_FORM_DATA(state,data){
        //     state.form_data = data
        // },
    },
    actions: {
        ResetData({ state, rootState }) {
            rootState.table.btn_table = [];
            rootState.table.items = [];
            rootState.table.data_extend = {};
            rootState.table.data_expand = {};
            rootState.table.headers = [];
            rootState.table.btns = [];
            rootState.table.chack_permission = 'd-none';
            rootState.table.pagination = false;
            rootState.table.paginate = {
                page: 1,
                itemsPerPage: 0,
                total: 0,
                row_pre_page: false
            };
            rootState.table.select_view = false
            rootState.table.bulk_selected = [],
                state.url = null
            state.name_function = ''
            state.collection = ''
            state.id = '',
                state.parent_page = '',

                // form
                rootState.form.filters = [];
            rootState.form.formData = null;
            rootState.form.form_data = {};
            rootState.form.style_form = [];
            rootState.form.action = '';
            rootState.form.notify = {
                color: null,
                visible: false,
                msg: '',
                note: ''
            };
            rootState.form.modal = {};
            rootState.form.loader = false;
            rootState.form.dialog = false;
            rootState.form.stepper = {}
            rootState.form.filter_year = false;
            rootState.form.filter_year_id = null;
            return true;
        },
    },
    modules: {
        assign_form,
        auth, table, form, category, user, appointment, notifications,
        camp, type, square, question, assign, question_category,
        station,
        ticket, ticket_subject, License,
        order, pledge, service, temp_licenses, eng_guides, specialists,
        countries, re_assign_order,
        notification

    }
});